<template>
    <div class="app-layout" :class="seperator">
        <header id="app_toolbar" style="height: 64px">
            <div id="slot">
                <slot name="toolbar"></slot>
            </div>
            <div class="app-toolbar-effect sp-z-3"
                 v-bind:style="{ opacity: shadowOpacity }">
            </div>
        </header>
        <div id="app_toolbar_push" style="height: 64px"></div>
        <main id="app_body">
            <slot @setExtended="setExtended"/>
        </main>
        <footer>
            <slot name="footer"></slot>
        </footer>
        <sp-overlay/>
    </div>
</template>

<script>
    // import { mapGetters } from "vuex";

    export default {
        name: "LayoutToolbar",
        props: ["seperator", "extended"],
        data() {
            return {
                shadowOpacity: 0,
                alerts: [],
                scrollExtend: false,
            };
        },
        computed: {
            // ...mapGetters("app", ["toolbarColors", "toolbarOffset"]),
            // toolbarHeight() {
            //     const height = 64 + this.toolbarOffset;
            //     console.log(height);
            //     return `${height}px`;
            // },
        },
        created() {
            window.addEventListener("scroll", this.handleScroll);
            this.handleScroll();

            // global event for displaying alerts
            this.$root.$on("alert", (alert) => {
                this.alerts.push(alert);
            });
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            setExtended(extended) {
                this.extended = extended;
            },
            handleScroll() {
                const { scrollY } = window;
                const scrollTarget = 50;
                const target = 1;
                // Any code to be executed when the window is scrolled
                const percentage = (scrollY) / scrollTarget;
                const value = Math.max(0, Math.min(target, (target) * percentage));
                // if (value !== lastOpacity) {
                //     $toolbarEffect.css("opacity", value);
                //     lastOpacity = value;
                // }
                this.scrollExtend = scrollY > 5;
                if (value <= 1) {
                    this.shadowOpacity = value;
                } else {
                    this.shadowOpacity = 1;
                }
            },
        },
    };
</script>

<style lang="scss">
    body {
        overflow-y: scroll;
    }
</style>


<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/layout.scss";
    //@import "@/assets/scss/layout.scss";
    @import "@hagbardpaulson/spatial-beta/dist/base/helpers.scss";

    #app {
        display: grid;
        width: 100%;
        grid-template-rows: minmax(min-content, max-content) 1fr;

        #app_toolbar {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            background: white !important;
            grid-column: 1;
            grid-row: 1;
            width: 100%;
            height: 64px;
            position: fixed;
            z-index: 99;
            transition: .2s;


            #slot  {
                padding-top: 16px;
                padding-bottom: 16px;
                display: flex;
                align-items: center;
                height: 64px;
                @include notch-padding-left();
                @include notch-padding-right();

                @media only screen and (max-width: $mobile-trigger-width) {
                    &.extended {
                        height: calc(64px + 64px);
                    }
                }

                #action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: auto;
                    height: 32px;

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $colorDark1;
                        font-size: 1.5rem;
                        margin: auto 4px auto -4px;
                        padding: 0;
                        line-height: 32px;
                        height: 32px;
                        width: 32px;
                        cursor: pointer;
                        border-radius: 4px;
                        transition: .2s;
                        position: relative;

                        &:hover {
                            color: $colorDark2;
                        }

                        i {
                            line-height: 32px;
                            height: 32px;
                            width: 32px;
                            font-size: 1.2rem;
                        }
                    }
                }

                h1 {
                    color: $colorDark2;
                    font-size: 0.9rem;
                }

                .breadcrumbs {
                    padding: 6px 0 6px 15px;
                    border-left: 1px solid $colorLight1;
                    margin: 0 0 0 15px;
                }
            }

            #profile {
                margin: 16px 0;
                grid-column: 2;
                width: 32px;
                height: 32px;
                @include notch-margin-right();
            }

            .app-toolbar-effect { // effect for fading drop shadow
                background: white;
                z-index: -1;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        #app_toolbar_push {
            // toolbar push, since toolbar is fixed
            height: 64px;
            grid-column: 1;
            grid-row: 1;
            transition: .2s;

            @media only screen and (max-width: $mobile-trigger-width) {
                &.extended {
                    height: calc(64px + 64px);
                }
            }
        }

        main {
            grid-column: 1;
            grid-row: 2;
        }
    }
</style>
