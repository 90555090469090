<template>
    <layout-empty>
        <div class="page-error">
            <h1>404</h1>
            <p>page not found</p>
            <router-link
                :to="{ name: 'forms' }"
                class="sp-button-filled"
            >Tillbaka</router-link>
        </div>
    </layout-empty>
</template>

<script>
    export default {

    };
</script>


<style lang="scss" scoped>
    @import "@/assets/scss/variables.scss";

    .page-error {
        flex: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            color: $colorAccent;
            font-size: 1.2rem;
        }

        p {
            color: $colorDark1;
        }

        .sp-button-filled {
            margin: 10px 0 0 0;
        }
    }
</style>
