import Vue from "vue";
import Router from "vue-router";

import mainRoutes from "@/router/routes/main";

import statusRoutes from "@/router/routes/status";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
    ].concat(mainRoutes, statusRoutes),
});

// router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));
export default router;
