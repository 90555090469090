import spatialBeta from "@hagbardpaulson/spatial-beta/dist/@hagbardpaulson/spatial-beta";

import Vue from "vue";
import App from "./areas/App.vue";

import "@mdi/font/css/materialdesignicons.min.css";
import "@hagbardpaulson/spatial-beta/dist/@hagbardpaulson/spatial-beta.min.css";
import "@hagbardpaulson/spatial-beta/dist/directives";
import "./assets/themes/default.scss";

import router from "./router";

// map layouts
import LayoutEmpty from "./areas/shared/layouts/LayoutEmpty.vue";
import LayoutToolbar from "./areas/shared/layouts/LayoutToolbar.vue";
import PageGrid from "./areas/shared/layouts/PageGrid.vue";
import PageGridChild from "./areas/shared/layouts/PageGridChild.vue";

// map layouts, theese are applied via the route meta
Vue.component("layout-empty", LayoutEmpty);
Vue.component("layout-toolbar", LayoutToolbar);
Vue.component("page-grid", PageGrid);
Vue.component("page-grid-child", PageGridChild);

// use spatial components
Vue.use(spatialBeta);

Vue.config.productionTip = false;

new Vue({
    router,
    // store,
    // data: globalData,
    // methods: globalMethods,
    render: h => h(App),
}).$mount("#app");
