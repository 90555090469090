<template>
    <div id="app">
        <div id="cover"
             class="anim-fadeSlideOut anim-duration-20"
             :style="{ 'background-image':'url('+ require('@/assets/images/cover.jpg') +')' }"
        ></div>
        <div id="wrapper">
            <div id="wrapper_name" class="anim-fadeSlideIn anim-delay-6 anim-duration-5">
                <h4>100% pure water</h4>
            </div>
            <div id="wrapper_console" class="anim-fadeIn anim-delay-1 anim-duration-5">
                <h1>
                    For those without <br/>
                    compromise.
                </h1>
            </div>

            <h4 class="anim-fadeIn anim-delay-1 anim-duration-5">
                We're currently building the website
            </h4>
        </div>
    </div>
</template>

<script>
    export default {
        name: "App",
        data() {
            return {
                value: "World",
            };
        },
    };
</script>

<style lang="scss">
    @import "@/assets/scss/site.scss";
    @import "@/assets/scss/animations.scss";

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        min-width: 100%;
        min-height: 100%;
    }

    #app {
        display: flex;
        flex-direction: column;

        #cover {
            z-index: -1;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-size: cover;
            background-position: center;
            // image-rendering: pixelated;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background:rgba(30,30,50,0.1)
            }
        }

        #wrapper {
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            #wrapper_name {
                display: flex;
                align-items: center;
                // opacity: .8;

                img {
                    width: 25px;
                    height: 25px;
                }

                h4 {
                    margin: -2px 0 0 4px;
                    color: rgba($colorDark2, .8);
                }
            }

            h1 {
                font-size: 2rem;
                text-align: center;
                margin: 20px 0 20px 0;
                color: #1a1a1a;

                @media only screen and (max-width: $mobile-trigger-width) {
                    font-size: 1.5rem;
                }
            }


            >h4 {
                text-align: center;
                color: rgba($colorDark2, .8);
            }
        }

    }
</style>
