import _main from "@/areas/main/views/_main.vue";
import home from "@/areas/main/views/home.vue";

const routes = [
    {
        path: "/",
        meta: { layout: "layout-toolbar" },
        component: _main,
        children: [
            {
                path: "/",
                name: "home",
                meta: { isRoot: true, root: "home", displayName: "Kvantbit" },
                component: home,
            },
            // {
            //     path: "contact",
            //     name: "contact",
            //     meta: { isRoot: true, root: "contact", displayName: "Kontakt" },
            //     component: contact,
            // },

        ],
    },
];

export default routes.map((route) => {
    const defaultMeta = {
        layout: "layout-toolbar",
    };
    const meta = { ...route.meta, ...defaultMeta };
    return { ...route, meta };
});
